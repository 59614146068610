@import "../../style.scss";

$accent: $accentBlue;

.nav {
    position: fixed;
    height: auto;
    width: 100%;
    z-index: 30;

    .navbar {
        display: flex;
        align-items: center;
        height: 200px;
        transition: 0.3s ease-in-out;
        color: $bg;

        @include mobile {
            height: 100px;
        }

        .container {
            flex-direction: row;
            justify-content: space-between;

            .left {
                display: flex;
                width: 1px;

                .openNav {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    gap: 7px;
                    height: inherit;
                    cursor: pointer;
                    z-index: 30;

                    .navLines {
                        transition: 0.1s ease-in-out;
                        background: currentColor;
                        height: 1px;
                        width: 50px;
                        z-index: 30;
                    }

                    .navLines:last-child {
                        width: 30px;
                    }
                }

                .openNav.active {
                    .navLines {
                        background: $accent;
                        transform: rotate(45deg) translate(1px, 5px);
                    }

                    .navLines:last-child {
                        width: 50px;
                        transform: rotate(-45deg) translate(1px, -5px);
                    }
                }
            }

            .logo {
                width: auto;
                height: 100px;
                color: inherit;
                transition: 0.2s ease-in-out;
                text-transform: inherit;
                text-align: center;

                @include mobile {
                    height: 50px;
                }

                img {
                    height: inherit;
                }

                h2 {
                    color: inherit;
                    height: auto;
                    transition: 0.2s ease-in-out;
                }

                span {
                    display: flex;
                    transition: 0.2s ease-in-out;
                    color: $accent;
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                width: 1px;
                gap: 50px;

                .links {
                    display: flex;
                    gap: 30px;
                    transition: 0.2s ease-in-out;

                    @include desktop {
                        display: none;
                    }

                    .call {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }

                .language {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    .boxLanguage {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        height: 30px;
                        width: 30px;
                        padding: 10px;
                        gap: 10px;
                        transition: 0.2s ease-in-out;

                        button {
                            background: none;
                            text-align: center;
                            padding: 0;
                            text-transform: uppercase;
                            color: inherit;
                        }

                        .expand {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            position: absolute;
                            width: 30px;
                            padding: 10px;
                            padding-top: 20px;
                            background: $accent;
                            top: 40px;
                            gap: 10px;
                            opacity: 0;

                            button {
                                color: $bg;
                            }
                        }

                        .expand.active {
                            opacity: 1;
                        }
                    }

                    .boxLanguage.active {
                        background: $accent;
                    }
                }

                a {
                    color: currentColor;
                    text-decoration: none;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    transition: 0.2s ease-in-out;

                    &:hover {
                        color: $accent;
                    }
                }
            }
        }
    }

    .navbar.scroll {
        color: $textColor !important;
        background: $bg;
        height: 80px;

        .container {

            .logo {
                width: auto;

                img {
                    height: inherit;
                    margin-top: -25px;
                }

                h2 {
                    height: 0;
                    overflow: hidden;
                }

                span {
                    height: 0;
                    overflow: hidden;
                }
            }
        }
    }

    .navbar.active {
        background: $bg;
        color: $textColor;
    }

    .navModal {
        height: 0;
        padding-top: 80px;
        overflow: hidden;
        transition: 0.2s ease-in-out;

        .left {
            display: flex;
            align-items: flex-end;
            height: 100vh;
            top: 0;

            .languageBtn {
                display: flex;
                position: relative;
                left: 0;
                bottom: 10vh;
                color: $bg;
                text-transform: uppercase;
                cursor: pointer;

                .selectedLanguage {
                    background: $accent;
                    padding: 20px;
                }

                .selectLanguage {
                    display: flex;
                    position: relative;
                    background: $accent;

                    span {
                        padding: 20px;

                        &:hover {
                            background: $accentColorHover;
                        }
                    }
                }
            }
        }

        .right {
            display: flex;
            justify-content: space-around;
            width: 90vw;
            gap: 5vh;

            @include tablet {
                flex-direction: column;
            }

            h2 {
                font-weight: normal;
            }

            h3 {
                text-transform: initial;
                color: $textColor;
            }

            a {
                text-decoration: none;
                color: $textColor;
                transition: 0.2s ease-in-out;

                &:hover {
                    color: $accent;
                }
            }

            a.active {
                color: $accent;
            }

            .mainLinks {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 0 15px;
            }

            .subLinks {
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 0 15px;

                .top {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                .bottom {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    h3 {
                        color: $accent;
                    }

                    h4 {
                        font-weight: normal;
                        color: $textColor;
                    }
                }
            }
        }
    }

    .navModal.open {
        height: calc(100vh - 200px);
        background: $bg;

        .left {
            position: absolute;

            @include mobile {
                right: 0;
            }
        }

        @include mobile {
            height: calc(100vh - 150px);
        }
    }
}