@import "../../style.scss";
@import "../../wp-style.scss";

$accent: $accentYellow;

.news {

    .container {
        display: flex;
        flex-direction: column;

        .grid {
            display: flex;
            flex-wrap: wrap;
            gap: 4vh;
            margin-top: 50px;

            @include tablet {
                flex-direction: column;
            }

            .item {
                position: relative;
                width: 25%;
                aspect-ratio: 2 / 3;
                overflow: hidden;

                @include tablet {
                    width: 100%;
                }

                .box {
                    position: absolute;
                    bottom: 0;
                    width: -webkit-fill-available;
                    color: $bg;
                    font-size: 1.5rem;
                    padding: 20px;
                    background: rgba($accent, 0.7);
                    z-index: 10;

                    .top {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .bottom {
                        height: 0;
                        width: -webkit-fill-available;
                        overflow: hidden;
                        transition: 0.2s ease-in-out;

                        span {
                            font-size: initial;
                        }
                    }
                }

                .image {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.2s ease-in-out;
                }

                &:hover {
                    cursor: pointer;

                    .image {
                        transform: scale(1.05);
                    }

                    .box {

                        .bottom {
                            height: 70px;
                        }
                    }
                }
            }
        }
    }
}