@import "../../style.scss";

$accent: $accentGreen;

.kontakt {
    display: flex;
    flex-direction: column;

    .container {

        h2 {
            margin-bottom: 30px;
        }

        h4 {
            color: $accent;
        }

        .group {
            margin-bottom: 50px;

            .listLink {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding: 15px 20px;
                padding-left: 60px;
                color: $textColor;
                font-weight: bold;
                text-decoration: none;
                transition: 0.2s ease-in-out;

                &:hover {
                    background: $bgBox;
                }

                svg {
                    position: absolute;
                    left: 20px;
                    color: $accent;
                    width: 30px;
                    height: 30px;
                }

                .label {

                    @include tablet {
                        display: none;
                    }
                }
            }
        }
    }
}