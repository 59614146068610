.wp-content {
    max-width: 100%;
    gap: 10px;

    h2 {
        margin-top: 30px;
    }

    p {
        margin-bottom: 20px;
    }

    figure {
        display: flex;
        flex-direction: column;
    }

    figcaption {
        font-weight: bold;
        padding: 10px 15px;
        background: $bgBox;
    }

    .is-layout-flow {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .wp-block-columns {
        display: flex;
        gap: 30px;

        @include tablet {
            flex-direction: column;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .icon-container {
        width: fit-content !important;

        svg {
            fill: $accent;
        }
    }
}