@import "../../../style.scss";

$accent: $accentBlue;

.viewTeam {

    .content {
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 50vh;
            background: rgba($accent, 0.75);
            padding-top: 100px;
            margin-bottom: 10vh;

            .titel {
                color: $bg;
                font-size: 2rem;
            }

            .name {
                color: $accent;
                font-size: 5rem;

                @include tablet {
                    text-align: center;
                }
            }
        }

        .container {

            .mailLink {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding: 15px 20px;
                padding-left: 60px;
                margin-bottom: 50px;
                color: $textColor;
                font-weight: bold;
                text-decoration: none;
                transition: 0.2s ease-in-out;

                &:hover {
                    background: $bgBox;
                }

                &::before {
                    content: 'mail_outline';
                    position: absolute;
                    font-family: 'Material Icons';
                    font-weight: normal;
                    font-size: 23px;
                    left: 20px;
                    width: 30px;
                    height: 30px;
                }

                .email {
                    color: $textColor;
                }

                .label {
                    color: $textColor;
                }

                .label {

                    @include tablet {
                        display: none;
                    }
                }
            }

            h2 {
                margin-bottom: 30px;
            }

            .wp-content {

                ul {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    list-style: none;
                    padding-left: 0;

                    li {
                        align-content: center;
                        padding: 15px 20px;
                        padding-left: 60px;
                        height: 30px;
                        color: $textColor;
                        transition: 0.2s ease-in-out;

                        &::before {
                            content: 'check_circle_outline';
                            position: absolute;
                            font-family: 'Material Icons';
                            font-size: 23px;
                            left: 20px;
                            width: 30px;
                            height: 30px;
                        }

                        span.text {
                            color: $textColor !important;
                        }

                        &:hover {
                            background: $bgBox;
                        }
                    }
                }
            }
        }
    }
}