@import "../../style.scss";

$accent: $accentBlue;

.footer {
    display: flex;
    flex-direction: column;
    margin-top: 15vh;

    .top {
        display: flex;
        gap: 30px;
        padding: 30px 5vw;

        @include tablet {
            flex-direction: column;
            align-items: center;
        }

        a {
            display: flex;
            align-items: center;
            gap: 5px;
            color: $textColor;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            h3 {
                color: $textColor;
                text-transform: initial;
                font-weight: bold;
            }

            svg {
                height: 50%;
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        color: $textColor;
        background: $bgLight;
        padding: 30px 5vw;
        gap: 50px;

        @include tablet {
            flex-direction: column;
            align-items: center;
        }

        .left {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $bg;
            gap: 50px;

            span {
                text-align: start;

                @include tablet {
                    text-align: center;
                }
            }

            b {
                color: $accent;
            }

            a {
                color: $bg;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        img {
            height: 80px;
        }
    }
}