@import "../../style.scss";

$accent: $accentBlue;

.header {

    .headerTop {
        display: flex;
        flex-direction: row;
        position: relative;
        object-fit: cover;
        width: 100vw;
        height: 50vh;
        overflow: hidden;
    }

    .headerBottom {
        position: relative;
        margin-top: -150px;
        margin-bottom: 100px;

        .top {
            display: flex;
            align-items: center;
            gap: 50px;
            height: 150px;
            width: 70%;
            background: rgba($accent, 0.7);

            @include tablet {
                width: 100%;
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 150px;
                width: 150px;
                background: none;

                svg {
                    width: 1.5em;
                    height: 1.5em;
                }

                &:hover {
                    background: $accent;
                }
            }

            a {
                color: $bg;
                text-decoration: none;
                font-weight: bold;
            }
        }

        .bottom {
            width: 70%;
            background: $accent;
            color: $bg;
            padding-top: 5vh;
            padding-bottom: 3vh;

            @include tablet {
                width: 100%;
            }

            h1 {
                margin-left: 5vw;
                padding-right: 5vw;
            }

            p {
                margin-left: 5vw;
                padding-right: 5vw;
            }
        }
    }
}