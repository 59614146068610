@import "../../style.scss";

$accent: $accentGreen;

.template {

    .container {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }
}