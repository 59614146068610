@import "../../style.scss";
@import "../../wp-style.scss";

$accent: $accentYellow;

.dienstleistungen {

    .container {
        display: flex;
        flex-direction: column;
        gap: 10vh;

        .grid {
            display: flex;
            flex-direction: column;
            gap: 10vh;
            margin-top: 50px;

            .item {
                display: flex;
                flex-direction: row;
                gap: 4vh;
                text-decoration: none;
                color: $textColor;

                h4 {
                    text-align: end;
                }

                @include tablet {
                    flex-direction: column;
                }

                &:nth-child(2n) {
                    flex-direction: row-reverse;

                    @include tablet {
                        flex-direction: column;
                    }

                    .right {
                        align-items: flex-start;

                        h4 {
                            text-align: start;
                        }

                        p {
                            text-align: start;
                        }
                    }
                }

                &:hover {

                    img {
                        transform: scale(1.05);
                    }
                }

                .left {
                    width: 50%;
                    height: fit-content;
                    overflow: hidden;

                    @include tablet {
                        width: 100%;
                    }

                    img {
                        width: 100%;
                        transition: 0.2s ease-in-out;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                    width: 50%;

                    @include tablet {
                        align-items: flex-start;
                        width: 100%;
                        padding: 0;
                    }

                    h4 {
                        margin-bottom: 20px;
                    }

                    p {
                        text-align: end;
                        margin-bottom: 50px;

                        @include tablet {
                            text-align: start;
                        }
                    }
                }
            }
        }
    }
}