@import "../../../style.scss";

$accent: $accentYellow;

.viewTeam {

    .content {
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 50vh;
            background: rgba($accent, 0.75);

            .titel {
                color: $bg;
                font-size: 2rem;
            }

            .name {
                color: $accent;
                font-size: 5rem;
            }
        }
    }
}